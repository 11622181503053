<template>
  <div class="mt-5">
    <div>
      <v-row no-gutters>
        <v-col class="pa-0" cols="12" lg="8" md="8" sm="12" xl="8">
          <v-text-field
            v-model="search"
            :label="label"
            hide-details
            outlined
            prepend-icon="mdi-magnify"
            single-line
            @input="typingSearch"
          />
        </v-col>
      </v-row>
      <v-data-table
        :expanded.sync="expanded"
        :headers="headers"
        :items="dataSource"
        :loading="loaded"
        :options.sync="options"
        :search="search"
        :server-items-length="totalItems"
        :single-expand="singleExpand"
        class="mt-5"
        item-key="uuid"
        show-expand
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="bg-expanded-row">
            <main class="detail-container-row">
              <v-row no-gutters>
                <v-col cols="12" lg="6" md="6" sm="6" xl="6">
                  <div>
                    <div class="display-align-center principal-detail-row">
                      <v-icon color="tableHeader">mdi-counter</v-icon>
                      <span class="ml-1">Tipo de serie</span>
                    </div>
                  </div>
                  <div class="px-6 mt-3">
                    <v-row class="info-element-text" no-gutters>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Nombre</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="8"
                        md="12"
                        sm="12"
                        xl="8"
                      >
                        <span>
                          {{ item.name || "No hay información" }}
                        </span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Mascara</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="8"
                        md="12"
                        sm="12"
                        xl="8"
                      >
                        <span>{{ item.mask || "No hay información" }}</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="4"
                        md="12"
                        sm="12"
                        xl="4"
                      >
                        <span>Secuencia</span>
                      </v-col>
                      <v-col
                        class="mt-2"
                        cols="12"
                        lg="8"
                        md="12"
                        sm="12"
                        xl="8"
                      >
                        <span>{{ item.sequence || "No hay información" }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </main>
          </td>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
            :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.name }}</span>
            </td>
            <td>
              <span>{{ item.mask }}</span>
            </td>
            <td>
              <span>{{ item.sequence }}</span>
            </td>
            <td>
              <div class="display-align-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="editRow(item, 'CrudTypeSerie', 'edit')"
                        class="mx-2"
                        v-if="hasAccess('/series-types', 'POST')"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                    >
                      <v-icon color="tableActions">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar tipo de series</span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="deleteRow(item, 'CrudTypeSerie', 'delete')"
                        class="mx-2"
                        v-if="hasAccess('/series-types', 'DELETE')"
                        v-bind="attrs"
                        v-on="on"
                        :color="isExpanded ? 'trExpandedRow' : 'white'"
                        fab
                        small
                        elevation="0"
                    >
                      <v-icon color="tableActions">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar tipo de series</span>
                </v-tooltip>
              </div>
            </td>
            <td @click="expand(!isExpanded)">
              <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
      :dialog="dialog"
      :iteName="'Tipo de serie'"
      :type="type"
      @cancelAction="dialog = false"
      @closeAction="closeAction"
      @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";

export default {
  name: "TypeSerieResume",
  components: { ActionPopUp },
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      module: "series-types",
      label: "Escribe el nombre o la secuencia",
    };
  },
  methods: {
    afterProcess() {
      this.headers = [
        {
          text: "Nombre",
          sortable: true,
          value: "name",
        },
        {
          text: "Mascara",
          sortable: true,
          value: "mask",
        },
        {
          text: "Secuencia",
          sortable: true,
          value: "sequence",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ];
    },
  },
};
</script>

<style scoped></style>
