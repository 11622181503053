import { render, staticRenderFns } from "./BottomActions.vue?vue&type=template&id=5ecafe5f&scoped=true&"
import script from "./BottomActions.vue?vue&type=script&lang=js&"
export * from "./BottomActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecafe5f",
  null
  
)

export default component.exports