<template>
  <v-row no-gutters>
    <v-col
        class="display-item-start"
        cols="12"
        lg="6"
        md="4"
        sm="12"
        xl="6"
        xs="12"
    >
      <slot :item="slotLeftItem" name="leftAction">
        <h1 class="title-info">{{ title }}</h1>
      </slot>
    </v-col>
    <v-col
        class="display-item-end"
        cols="12"
        lg="6"
        md="8"
        sm="12"
        xl="6"
        xs="12"
    >
      <slot v-if="showRightItem" :item="slotRightItem" name="rightAction">
        <v-btn
            v-if="showSecondaryBtn"
            class="btn-active v-btn-secondary"
            rounded
            :disabled="!disableBtnSecondary"
            @click="setDefaultSecondaryAction"
        >
          {{ secondaryBtnTitle }}
        </v-btn>
        <div v-if="showIconAction" class="mr-2 cursor-pointer-action">
          <!--          <v-icon size="40" @click="actionIcon">{{ iconAction }}</v-icon>-->
          <v-btn
              class="btn-active v-btn-primary"
              rounded
              @click="actionIcon"
          >
            Imprimir
          </v-btn>
        </div>
        <v-btn
            :disabled="!validBtn"
            class="btn-active v-btn-primary"
            rounded
            @click="setDefaultPrimaryAction"
        >
          {{ principalBtnTitle }}
        </v-btn>
      </slot>
    </v-col>
    <v-col cols="12" lg="6" md="12" sm="12" xl="6" xs="12">
      <div class="secondary-action">
        <slot :item="slotSecondaryItem" name="secondaryFooterAction">
          <span class="subtitle-info">{{ subtitle }}</span>
        </slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "topInfoAction",
  props: {
    title: {type: String, default: ""},
    subtitle: {type: String, default: ""},
    slotRightItem: {
      type: Object,
      default: () => {
      },
    },
    slotLeftItem: {
      type: Object,
      default: () => {
      },
    },
    slotSecondaryItem: {
      type: Object,
      default: () => {
      },
    },
    principalBtnTitle: {
      type: String,
      default: "",
    },
    secondaryBtnTitle: {
      type: String,
      default: "",
    },
    validBtn: {
      type: Boolean,
      default: true,
    },
    showRightItem: {
      type: Boolean,
      default: true,
    },
    showSecondaryBtn: {
      type: Boolean,
      default: false,
    },
    showIconAction: {
      type: Boolean,
      default: false,
    },
    iconAction: {
      type: String,
      default: '',
    },
    disableBtnSecondary: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    setDefaultPrimaryAction() {
      this.$emit("setDefaultPrimaryAction");
    },
    setDefaultSecondaryAction() {
      this.$emit("setDefaultSecondaryAction");
    },
    actionIcon() {
      this.$emit("actionIcon");
    }
  },
};
</script>

<style scoped></style>
