import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'

Vue.config.productionTip = false

/** Interceptor*/
import interceptorsSetup from '@/plugins/axios'

interceptorsSetup()

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
Vue.use(VueMask);
