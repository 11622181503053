import { render, staticRenderFns } from "./ordersResume.vue?vue&type=template&id=686c646b&scoped=true&"
import script from "./ordersResume.vue?vue&type=script&lang=js&"
export * from "./ordersResume.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686c646b",
  null
  
)

export default component.exports