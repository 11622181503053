<template>
  <div class="mt-5">
    <div>
      <v-data-table
          :headers="headers"
          :items="value"
          class="mt-5"
          item-key="uuid"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'"
          >
            <td>
              <span>{{ item }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import moduleMixin from "@/mixins/moduleMixin";


export default {
  name: "registerResume",
  components: {},
  mixins: [moduleMixin],
  props:{
    value:{type: Array, default:()=>[]}
  },
  data() {
    return {
      module: "patients",
      headers: [
        {
          text: "Series",
          value: "",
          sortable: false,
        },
      ]
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.personal-information-text > .name {
  font-family: "Montserrat-Regular";
  font-size: 17px;
}

.personal-information-text > .subtitle {
  font-family: "Montserrat-Regular";
  font-size: 14px;
}
</style>
