<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :title="showTitle"
        :valid-btn="validForm"
        principal-btn-title="Guardar"
        secondary-btn-title="Cancelar"
        subtitle="Llena el formulario y presiona click en el boton de guardar"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">
      <TypeSerieForm
          :current-element="currentElement"
          @validFormInput="validFormInput"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Tipo de serie'"
        :message="message"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import TopInfoAction from "../components/TopInfoAction.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import TypeSerieForm from "@/components/typeSeries/TypeSerieForm.vue";

export default {
  name: "CRUDTypeSerie",
  mixins: [CRUDMixin],
  props: {
    pathBack: {type: String, default: "series-types"},
  },
  components: {
    TypeSerieForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Tipo de series",
          disabled: false,
          path: "/series-types",
        },
        {
          text: `${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } Tipo de serie`,
          disabled: true,
          path: "/series-types",
        },
      ],
      currentElement: {
        name: "",
        mask: "",
        sequence: "",
      },
      module: "series-types",
      titleCreate: "Crea un nuevo tipo de serie",
      titleEdit: "Edita un tipo de serie",
    };
  },

  methods: {
    validFormInput(e) {
      this.validForm = e;
    },
    beforeSend() {
      this.currentElement.active = true;
    },
  },
};
</script>

<style scoped></style>
