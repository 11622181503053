<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        :validBtn="hasAccess('/series-types', 'POST')"
        principal-btn-title="Nuevo tipo de serie"
        subtitle="Crea, busca, filtra y visualiza tipos de series"
        title="Tipo de series"
        @setDefaultPrimaryAction="createNewStore"
    />
    <TypeSerieResume/>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "../components/TopInfoAction.vue";
import moduleMixin from "@/mixins/moduleMixin";
import TypeSerieResume from "@/components/typeSeries/typeSerieResume.vue";

export default {
  name: "Stores",
  mixins: [moduleMixin],
  components: {TypeSerieResume, TopInfoAction, Breadcrumbs},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Tipo de series",
        disabled: true,
        path: "/series-types",
      },
    ],
  }),
  methods: {
    createNewStore() {
      this.$router.push({
        name: "CrudTypeSerie",
        params: {crudAction: "create"},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
