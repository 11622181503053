<template>
  <v-app-bar :dark="false" :elevation="0" app class="backofficeAppBar hiddePrintElement">
    <v-img
        :lazy-src="logo"
        :src="logo"
        alt="BACKOFFICE"
        class="cursor-pointer-action mx-0 mx-md-16"
        max-width="50"
        style="background: white; border-radius: 50%"
        @click="$router.push('/dashboard')"
    />
    <div v-for="item in items" :key="item.title" class="d-none d-md-block">
      <v-btn
          v-if="enableRoute(item)"
          class="btn-link"
          dark
          text
          @click="navigate(item.path)"
      >
        {{ item.title }}
      </v-btn>
    </div>
    <v-spacer/>
    <div class="d-none d-md-flex display-align-center mr-2">
      <div>
        <span class="login-user-text"
        >{{ getDayTime }}, {{ getUser.name || "" }}</span
        >
      </div>
      <div class="ml-4">
        <v-avatar size="40">
          <img
              :alt="getUser.name"
              src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
          />
        </v-avatar>
      </div>
    </div>
    <v-btn class="hiddePrintElement" dark icon @click="toggleTheme">
      <v-icon>mdi-brightness-4</v-icon>
    </v-btn>
    <v-menu :min-width="140" transition="scroll-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="hiddePrintElement" dark icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense class="d-none d-md-block">
        <v-list-item
            v-for="item in menu"
            :key="item.title"
            link
            @click="handleClickMenu(item)"
        >
          <v-list-item-content>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-content>

          <v-list-item-content>
            <v-list-item-title class="menu-title"
            >{{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense class="d-block d-md-none">
        <v-list-item
            v-for="item in menuResponsive"
            :key="item.title"
            link
            @click="handleClickMenu(item)"
        >
          <div class="d-flex flex-column my-2">
            <v-icon>{{ item.icon }}</v-icon>
            <v-spacer/>
            <v-list-item-title class="menu-title ml-2">
              {{ item.title }}
            </v-list-item-title>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Logo from "../assets/images/logoHidalgobg.png";
import router from "../router";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "AppBar",
  data() {
    return {
      logo: Logo,
      items: [
        {title: "Calculadora", icon: "mdi-badge-account", path: "/dashboard"},
        {title: "Usuarios", icon: "mdi-badge-account", path: "/employees"},
        {title: "Tipo de series", icon: "mdi-badge-account", path: "/series-types"},
        {title: "Gestión de ordenes", icon: "mdi-badge-account", path: "/orders"}
      ],
      menu: [{title: "Cerrar sesión", icon: "mdi-logout", path: "/"}],
      menuResponsive: [
        {title: "Tipo de series", icon: "mdi-ticket-confirmation", path: "/series-types"},
        {title: "Usuarios", icon: "mdi-badge-account", path: "/employees"},
        {title: "Calculadora", icon: "mdi-badge-account", path: "/dashboard"},
        {title: "Gestión de ordenes", icon: "mdi-badge-account", path: "/orders"},
        {title: "Cerrar sesión", icon: "mdi-logout", path: "/"}
      ],
    };
  },
  computed: {
    getDayTime() {
      const hour = moment().hour();
      if (hour >= 6 && hour < 12) {
        return "Buenos días";
      } else if (hour >= 12 && hour < 20) {
        return "Buenas tardes";
      } else {
        return "Buenas noches";
      }
    },
    ...mapGetters("auth", ["getResources", "getUser"])
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      this.$store.commit("auth/CLEAR_TOKEN");
      router.replace({path: "/"});
    },
    handleClickMenu(item) {
      switch (item.path) {
        case "/":
          this.logout();
          break;
        default:
          router.push(item.path);
          break;
      }
    },
    enableRoute(item) {
      let hasAccess = false;
      if (item.path === '/dashboard') {
        hasAccess = true;
      }
      if (this.getResources?.length) {
        this.getResources.forEach((itemRes) => {
          if (itemRes?.resourceName === item.path) {
            if (itemRes.permission === "GET") {
              hasAccess = true;
            }
          }
        });
      }
      return hasAccess;
    },
    navigate(path) {
      if (path !== this.$router.currentRoute.path) {
        router.push(path);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.backofficeAppBar {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
          var(--v-appBarInitial-base) 19%,
          var(--v-appBarInitial-base) 88%
  );
}

.btn-link {
  font-family: "Montserrat-Light";
  text-transform: none;
}

.menu-title {
  font-family: "Montserrat-Medium";
}

.login-user-text {
  font-size: 16px;
  font-family: "Montserrat-Regular";
  color: var(--v-whiteText-base);
}

.circle > div {
  border: 2px solid var(--v-avatarBorder-base);
}


@media print {
  .hiddePrintElement {
    display: none;
  }
}
</style>
