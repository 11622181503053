<template>
  <v-container>
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction :show-right-item="true" subtitle="Calculadora de placas"
                   @setDefaultPrimaryAction="createNewRegister"
                   @setDefaultSecondaryAction="downloadExcel"
                   :show-secondary-btn="true"
                   :secondary-btn-title="'Descargar Excel'"
                   principal-btn-title="Calcular"
                   :disable-btn-secondary="validDownloadExcel"
                   :valid-btn="validDownloadExcel"
                   title="Calculadora"/>

    <calculator-form :current-element="currentElement" @validFormInput="validFormInput"></calculator-form>
    <v-row>
      <v-col v-if="loading" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div class="mt-10 display-align-center display-item-center">
          <v-progress-circular
              color="image-container-text"
              indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col v-if="!loading" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <register-resume :value="valueCalculator"></register-resume>
      </v-col>
    </v-row>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Calculadora'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>

  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";
import TopInfoAction from "../components/TopInfoAction";
import RegisterResume from "@/components/registers/RegisterResume.vue";
import CalculatorForm from "@/components/calculator/calculatorForm.vue";
import customStore from "@/services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";


export default {
  name: "Dashboard",
  components: {ActionPopUp, CalculatorForm, RegisterResume, Breadcrumbs, TopInfoAction},
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: true,
        path: "/dashboard",
      },
    ],
    validForm: false,
    currentElement: {
      init: '',
      end: '',
      total: '',
      seriesTypeId: ''
    },
    valueCalculator: [],
    loading: false,
    dialog: false,
    type: 'error',
    message: ''
  }),
  computed: {
    validDownloadExcel() {
      return (this.validForm && (this.currentElement.end !== '' || this.currentElement.total !== ''))
    }
  },
  methods: {
    validFormInput(e) {
      this.validForm = e;
    },
    async createNewRegister() {
      try {
        this.loading = true
        console.log('entro')
        const data = {
          init: this.currentElement.init.toUpperCase(),
          seriesTypeId: this.currentElement.seriesTypeId
        }
        if (this.currentElement.total !== '') {
          data.total = parseInt(this.currentElement.total)
        }
        if (this.currentElement.end !== '') {
          data.end = this.currentElement.end.toUpperCase()
        }


        const response = await customStore.postCalculatorData('calculator/calculate', data)
        console.log(response, 'response')
        this.valueCalculator = response?.series
        this.currentElement.total = response.total
        this.currentElement.end = response.end
      } catch (error) {
        console.log(error)
        this.dialog = true
        this.message = error?.response?.data?.details || ''
      } finally {
        this.loading = false
      }
    },
    closeAction() {
      this.dialog = false;
    },
    async downloadExcel() {
      const data = {
        init: this.currentElement.init.toUpperCase(),
        seriesTypeId: this.currentElement.seriesTypeId
      }
      if (this.currentElement.total !== '') {
        data.total = parseInt(this.currentElement.total)
      }
      if (this.currentElement.end !== '') {
        data.end = this.currentElement.end.toUpperCase()
      }
      await customStore.postPlatesExcelFile(data)
    }
  },
};
</script>

<style lang="scss" scoped>
.card-color-description-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px 20px 10px;
  background-color: var(--v-calendarDescriptionBg-base);

  .description-calender-text {
    color: var(--v-textCalendar-base);
    font-family: 'Montserrat-Regular';
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
  }

  .circle-container {
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }
}

.main-container-detail {
  .contract-container {
    height: auto;
    max-height: 75vh;
    display: flex;
    flex-direction: column;

    .contract-detail {
      padding: 10px;
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;

      span {
        font-family: 'Montserrat-Medium';
        font-size: 17px;
        font-weight: 600;
      }
    }

    .contract-detail::-webkit-scrollbar {
      width: 5px;
    }

    .contract-detail::-webkit-scrollbar-track {
      background: #f2f2f2;
    }

    .contract-detail::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 10px;
    }
  }

  .no-contracts {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--v-boxUpload-base);
    margin-top: 10px;
    font-family: 'Montserrat-Regular';

  }
}

</style>
