import { render, staticRenderFns } from "./CrudTypeSerie.vue?vue&type=template&id=39d8a460&scoped=true&"
import script from "./CrudTypeSerie.vue?vue&type=script&lang=js&"
export * from "./CrudTypeSerie.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d8a460",
  null
  
)

export default component.exports