import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import {ACCESS_TOKEN} from "@/utils/constants";
import PrintRegister from "@/views/PrintRegister.vue";
import Employees from "../views/Employees.vue";
import TypeSeries from "@/views/TypeSeries.vue";
import CrudTypeSerie from "@/views/CrudTypeSerie.vue";
import CRUDEmployee from "@/views/CRUDEmployee.vue";
import orders from "../views/orders.vue";
import CrudOrders from "../views/CrudOrders.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
            hideAppBar: true,
            hideNavigationDrawer: true,
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/employees",
        name: "Employees",
        component: Employees,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/series-types",
        name: "TypeSeries",
        component: TypeSeries,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/orders",
        name: "Orders",
        component: orders,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/printRegister/:id",
        name: "PrintRegister",
        component: PrintRegister,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudemployee/:crudAction/:id",
        name: "CrudEmployee",
        component: CRUDEmployee,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudtypeserie/:crudAction/:id",
        name: "CrudTypeSerie",
        component: CrudTypeSerie,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudorders/:crudAction/:id",
        name: "CrudOrders",
        component: CrudOrders,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {path: "*", redirect: "/"},
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    const isLogged = localStorage.getItem(ACCESS_TOKEN);
    const booleanLogged = JSON.parse(isLogged)
    if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
        if (booleanLogged === null) {
            next('/');
        } else {
            next();
        }
    } else if (to.path === '/' && booleanLogged !== null) {
        next('/dashboard');
    } else {
        next();
    }
})

export default router;