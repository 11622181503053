<template>
  <div>
    <v-row class="action-pop-up-container" justify="center">
      <v-dialog
          v-model="dialog"
          content-class="dialog-container"
          max-width="380"
          persistent
      >
        <v-card>
          <v-card-title class="text-h5">
            <v-icon v-if="type === 'delete'" color="alert" size="40"
            >mdi-alert-outline
            </v-icon>
            <v-icon v-if="type === 'error'" color="alert" size="40"
            >mdi-alert-circle-outline
            </v-icon>
            <v-icon v-if="type === 'errorImage'" color="alert" size="40"
            >mdi-alert-circle-outline
            </v-icon>
            <v-icon v-if="type === 'success'" color="success" size="40"
            >mdi-check-circle-outline
            </v-icon>
            <v-icon v-if="type === 'info'" color="info" size="40"
            >mdi-information-outline
            </v-icon>
          </v-card-title>
          <v-card-text v-if="type === 'delete'" class="mt-3">
            <div>
              <h1 class="confirm-action-label">Confirma la acción</h1>
            </div>
            <div class="mt-3">
              <span class="subtitle-action-label"
              >Realmente quieres eliminar este {{ iteName }} ?</span
              >
            </div>
          </v-card-text>
          <v-card-text v-if="type === 'error'" class="mt-3">
            <div>
              <h1 v-if="message" class="confirm-action-label error-label">
                {{ message }}
              </h1>
              <h1 v-else class="confirm-action-label"
              >Por favor verifica tu información e intenta de nuevo </h1
              >
            </div>
            <div class="mt-3">
              <span  class="subtitle-action-label">Un error ha ocurrido al guardar la información</span>
            </div>
          </v-card-text>
          <v-card-text v-if="type === 'errorImage'" class="mt-3">
            <div>
              <h1 class="confirm-action-label">
                Un error ha ocurrido al guardar la información
              </h1>
            </div>
            <div class="mt-3">
              <span class="subtitle-action-label"
              >Please only select images and try again</span
              >
            </div>
          </v-card-text>
          <v-card-text v-if="type === 'success'" class="mt-3">
            <div>
              <h1 class="confirm-action-label">Genial!</h1>
            </div>
            <div class="mt-3">
              <span v-if="!fileInfo" class="subtitle-action-label"
              >El {{ iteName }} {{ print ? ' se ha descargado correctamente' : 'se ha modificado correctamente' }}
              </span>
              <span v-else class="subtitle-action-label">
                The {{ iteName }} has been successfully uploaded
              </span>
            </div>
          </v-card-text>
          <v-card-text v-if="type === 'info'" class="mt-3">
            <div>
              <h1 class="confirm-action-label">{{ titleInfo }}</h1>
            </div>
            <div class="mt-3">
              <span class="subtitle-action-label">
               {{ subtitleInfo }}
              </span>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div
                :class="
                type !== 'delete'
                  ? 'full-width display-item-end mt-3 mb-3 mr-2 ml-2'
                  : 'full-width display-item-justify mt-3 mb-3 mr-2 ml-2'
              "
            >
              <v-btn
                  v-if="type === 'delete'"
                  color="secondary"
                  text
                  @click="cancelAction"
              >
                Cancelar
              </v-btn>
              <v-btn
                  v-if="type === 'delete'"
                  class="btn-active"
                  rounded
                  @click="deleteAction"
              >
                Eliminar {{ iteName }}
              </v-btn>
              <v-btn
                  v-if="type !== 'delete' && type !== 'info'"
                  :class="
                  type === 'error' || type === 'errorImage'
                    ? 'btn-active'
                    : 'btn-success'
                "
                  rounded
                  @click="closeAction"
              >
                Cerrar
              </v-btn>
              <v-btn
                  v-if="type === 'info'"
                  color="secondary"
                  text
                  @click="closeAction"
              >
                Continue
              </v-btn>
              <v-btn
                  v-if="type === 'info'"
                  class="btn-active"
                  color="info"
                  rounded
                  @click="infoAction"
              >
                {{ iteName }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ActionPopUp",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    iteName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "delete",
    },
    fileInfo: {
      type: Boolean,
      default: false,
    },
    titleInfo: {
      type: String,
      default: "",
    },
    subtitleInfo: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    print: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  methods: {
    deleteAction() {
      this.$emit("deleteAction");
    },
    cancelAction() {
      this.$emit("cancelAction");
    },
    closeAction() {
      this.$emit("closeAction");
    },
    infoAction() {
      this.$emit("infoAction");
    },
  },
};
</script>

<style lang="scss">
.dialog-container {
  .confirm-action-label {
    font-family: "Montserrat-Regular";
    font-size: 20px;
    font-weight: 600;
    color: var(--v-popUpText-base) !important;
  }

  .subtitle-action-label {
    color: var(--v-popUpText-base) !important;
    font-size: 16px;
    font-family: "Montserrat-Regular";
    font-weight: lighter;
  }

  .v-sheet.v-card {
    border-radius: 14px !important;
  }
}

.error-label:first-letter {
  text-transform: uppercase;
}
</style>
