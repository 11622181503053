<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <title-info-card icon="mdi-counter" textTitle="Tipo de serie">
              <template #contentCard>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="">
                      <label class="input-label" for="name">Nombre<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.name"
                          id="name"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ejemplo: Servicio particular"
                      />
                    </div>
                  </v-col>
                  <v-col class="mt-5" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="space-right-input">
                      <label class="input-label" for="state">Mascara<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.mask"
                          id="state"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ejemplo: AAA###"
                      />
                    </div>
                  </v-col>
                  <v-col class="mt-5" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="space-left-input">
                      <label class="input-label" for="city">Secuencia<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.sequence"
                          id="city"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ejemplo 123456"
                      />
                    </div>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "../../utils/validationRule";

export default {
  name: "TypeSerieForm",
  components: {TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      required,
      validForm: false,
    }
  },
  methods: {
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
  }
}
</script>

<style scoped>

</style>
