import Axios from "axios";
import {urlFull} from "@/services/config";

const BASE_LOGIN_URL = "/employees";

const employeeService = {
    postEmployee(data) {
        return Axios.post(urlFull(BASE_LOGIN_URL), data).then((res) => res.data);
    },
    getEmployeeById(data) {
        return Axios.get(urlFull(`${BASE_LOGIN_URL}/${data}`)).then(
            (res) => res.data
        );
    },
    getMeById() {
        return Axios.get(urlFull(`/authorization/me`)).then(
            (res) => res.data
        );
    },
    updateEmployee(data) {
        return Axios.post(urlFull(`${BASE_LOGIN_URL}`), data).then(
            (res) => res.data
        );
    },
    getRoles() {
        return Axios.get(urlFull(`/role`)).then((res) => res.data);
    },
    getRolesByEmployeeId(data) {
        return Axios.get(urlFull(`/role/employee/${data.uuid}`)).then(
            (res) => res.data
        );
    },
    updateRoleEmployee(employeeId, data) {
        return Axios.post(urlFull(`/role/employee/${employeeId}`), data).then(
            (res) => res.data
        );
    },
};

export default employeeService;
