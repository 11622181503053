<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <title-info-card icon="mdi-counter" textTitle="Ingresa los valores a calcular">
              <template #contentCard>
                <div class="mt-3 mb-3">
                  <span class="required-field-form">*</span><span>El número máximo de series es de 1 millón</span>
                </div>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
                    <div>
                      <label class="input-label" for="StartDate"
                      >Tipo de serie<span class="required-field-form">*</span></label
                      >
                      <v-autocomplete v-model="currentElement.seriesTypeId"
                                      :search-input.sync="search"
                                      :loading="loading"
                                      :items="availableTypeSeries"
                                      clearable
                                      item-value="uuid"
                                      :item-text="formatItemText"
                                      @change="getReferenceByUUID"
                                      :rules="required"
                                      color="geckoInput"
                                      hide-details
                                      outlined placeholder="Ex: Servicio particular"
                      />
                    </div>
                  </v-col>


                  <v-col class="mt-3" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                      <label class="input-label" for="name">Inicio de la serie {{ reference }}<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.init"
                          v-mask="reference"
                          :readonly="reference === ''"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          :placeholder="reference ? `Ejemplo: ${reference}` : 'Ejemplo: 7MNS35' "
                      />
                    </div>
                  </v-col>
                  <v-col class="mt-5" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="space-right-input">
                      <label class="input-label" for="state">Final de la serie {{ reference }}</label>
                      <v-text-field
                          v-model="currentElement.end"
                          v-mask="reference"
                          :readonly="reference === ''"
                          hide-details
                          outlined
                          color="backofficeInput"
                          :placeholder="reference ? `Ejemplo: ${reference}` : 'Ejemplo: 7MNS35' "
                      />
                    </div>
                  </v-col>
                  <v-col class="mt-5" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="space-left-input">
                      <label class="input-label" for="city">Total</label>
                      <v-text-field
                          v-model="currentElement.total"
                          :readonly="reference === ''"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Ejemplo: 65"
                      />
                    </div>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "../../utils/validationRule";
import customStoreService from "@/services/customStore";

let typingSerieType = null
export default {
  name: "CalculatorForm",
  components: {TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      required,
      validForm: false,
      search: '',
      availableTypeSeries: [],
      loading: false,
      module: 'series-types',
      page: 0,
      itemsPerPage: 500,
      reference: ''
    }
  },
  mounted() {
    this.searchTypeSerieAvailable()
  },
  methods: {
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    searchTypeSerie(evt) {
      clearInterval(typingSerieType)
      typingSerieType = setInterval(function () {
        this.searchTypeSerieAvailable(evt)
        clearInterval(typingSerieType)
      }.bind(this), 1000)
    },
    async searchTypeSerieAvailable(search) {
      const {data} = await customStoreService.getDataByModule(
          this.module,
          search === null ? '' : search,
          this.page,
          this.itemsPerPage,
          '',
          '',
          '',
          ''
      );
      if (data?.length) {
        this.availableTypeSeries = data
      } else {
        this.availableTypeSeries = []
      }
    },
    formatItemText(typeSerie) {
      return `${typeSerie?.name}`
    },
    getReferenceByUUID(evt) {
      if (evt) {
        const response = this.availableTypeSeries.find((item) => item.uuid === evt)
        console.log(response)
        if (response) {
          this.reference = response.mask
        } else {
          this.reference = ''
        }
      } else {
        this.reference = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
