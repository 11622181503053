import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                groupHeading: "#f8f9fa",
                backofficeGray: "#bdbdbd",
                backofficeSuccess: "#17ad37",
                backofficeInput: "#b0bec5",
                backofficeOrangeInitial: "#ff9800",
                backofficeOrangeFinish: "#e65100",
                appBarInitial: "#791d41",
                appBarFinish: "#101c2b",
                primary: "#9d2449",
                secondary: "#868c94",
                tableHeader: "#485364",
                tableDetailExpanded: "#eaf1fe",
                trExpandedRow: "#a0acbf",
                tableActions: "#8691a4",
                hideLabel: "#ff0000",
                borderBox: "#bbc7db",
                selectedBox: "#543f5e",
                alert: "#ff6f6f",
                statusRole: "#2d94f5",
                success: "#58cc58",
                whiteText: "#ffffff",
                avatarBorder: "#fff4c5",
                boxUpload: "#bbc7db",
                boxUploadText: "#303c4c",
                boxUploadedFiles: "#fbfafb",
                successImage: "#00b100",
                selectedImage: "#6a6a6a",
                textCalendar: "#1f2b3a",
                calendarDescriptionBg: "#bbc7db",
                completeStep: "#1f2b3a",
                title1: "#303c4c",
                textTableHeader: "#485364",
                popUpText: "#000000",
                info: "#219ebc"
            },
            dark: {
                groupHeading: "#1e1e1e",
                backofficeGray: "#bdbdbd",
                backofficeSuccess: "#17ad37",
                backofficeInput: "#b0bec5",
                backofficeOrangeInitial: "#ff9800",
                backofficeOrangeFinish: "#e65100",
                appBarInitial: "#1e1e1e",
                appBarFinish: "#1e1e1e",
                primary: "#9d2449",
                secondary: "#868c94",
                tableHeader: "#2e2e2e",
                tableDetailExpanded: "#494949",
                trExpandedRow: "#2e2e2e",
                tableActions: "#8691a4",
                hideLabel: "#ff0000",
                borderBox: "#bbc7db",
                selectedBox: "#c74e4e",
                alert: "#ff6f6f",
                statusRole: "#2570af",
                success: "#1d6f1d",
                whiteText: "#ffffff",
                avatarBorder: "#fff4c5",
                boxUpload: "#bbc7db",
                boxUploadText: "#303c4c",
                boxUploadedFiles: "#fbfafb",
                successImage: "#00b100",
                selectedImage: "#6a6a6a",
                textCalendar: "#ffffff",
                calendarDescriptionBg: "#2b2f34",
                completeStep: "#1E1E1E",
                title1: "#ffffff",
                textTableHeader: "#ffffff",
                popUpText: "#fff",
                info: "#ffffff"
            },
        },
    },
});
